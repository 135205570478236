<template>
  <div class='indexbox'>
    <div class="searchbox">
      <div class="left">
        <el-input v-model="keywords" class="inp" clearable placeholder="Type Text To Search" size='small' style="width: 200px" @change="pagechage(1)"></el-input>
        <el-button circle type="primary" @click="pagechage(1)">Search</el-button>
        <div class="name">Status Filter</div>
        <div>
          <el-select v-model="assembly_status" clearable placeholder="Order Generation Status" size='small' @change='pagechage(1)'>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div v-hasPermi="['an']" class="right">
        <button @click="$router.push('addNew')">+Add New</button>
      </div>
    </div>
    <div class="tablebox">
      <div v-loading='loading' class="draggable">
        <el-table :data="tableData" max-height='700px' stripe @selection-change="SelectionChange" @row-click="clickData">
          <el-table-column align='center' fixed label="Converting Order #" prop="convertingOrderNo" width='234'/>
          <el-table-column align='center' label="Converting Order Date" prop="convertingOrderDate" show-overflow-tooltip width='236'/>
          <el-table-column align='center' label="Converting Location" prop="convertingLocation" show-overflow-tooltip width='380'/>
          <el-table-column align='center' label="Converting Note" prop="convertingNote" show-overflow-tooltip width='188'/>
          <el-table-column align='center' label="Raw Material Transfer Status" prop="rawMaterialTransferStatus" show-overflow-tooltip width='280'/>
          <el-table-column align='center' label="Product Transfer Status" prop="productTransferStatus" show-overflow-tooltip width='280'/>
          <el-table-column align='center' label="Converting Order Status" prop="convertingOrderStatus" show-overflow-tooltip width='280'/>
        </el-table>
      </div>
      <el-pagination v-show="total>0" :current-page.sync="page" :page-size="page_size" :page-sizes="[10, 20, 50, 100]" :total="total" background
                     layout="total,sizes,prev, pager, next" @current-change='pagechage' @size-change="handleSizeChange"/>
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: 'Draft'
        }, {
          value: 2,
          label: 'Approved'
        },
        {
          value: 3,
          label: 'Started'
        },
        {
          value: 4,
          label: 'Completed'
        },
        {
          value: 5,
          label: 'Void'
        }
      ],
      assembly_status: '',
      keywords: '',
      order_time: [],
      total: 0,
      page: 1,
      page_size: 10,
      // tableData: [],
      loading: false,
      btn_loading: false,
      btn_loadinga: false,
      idsarr: [],
      btn_loadingb: false,
      btn_loadingc: false,
      Quantity: 0,
      tableData: [],
    };
  },
  computed: {
    ...mapGetters(['api_id']),
  },
  created() {
    sessionStorage.setItem('btn_num', '0')
    this.getlist()
  },
  methods: {
    // 获取基本数据
    getlist() {
      this.loading = true
      if (!this.$store.state.user.api_id) return this.loading = false;
      this.$post({
        url: "/app/converting_orders/pageList",
        data: {
          page: this.page,
          page_size: this.page_size,
          api_id: this.$store.state.user.api_id,
          keywords: this.keywords,
          rawMaterialTransferStatus: '',
          productTransferStatus: '',
          convertingOrderStatus: this.options[this.assembly_status - 1] ? this.options[this.assembly_status - 1].label : '',
          // page_size: this.page_size,
          // assembly_status: this.assembly_status,
        },
        success: (res) => {
          this.loading = false
          this.page = res.data.current_page
          this.page_size = Number(res.data.per_page)
          this.tableData = res.data.data
          this.total = res.data.total
          // this.Quantity = res.data.Quantity
          // setTimeout(() => {
          //   this.loading = false
          // }, 200);
          // console.log(res,'数据获取成功')
        },
        tip: () => {
        },
      })
    },
    // 每条数据的跳转
    clickData(row, event, column) {
      // console.log(row, event, column)
      sessionStorage.setItem("activeIndex", `addNew?id=${row.id}`);
      this.$router.push({path: 'addNew', query: {id: row.id}})
    },
    pagechage(page) {
      this.page = page
      this.tableData = []
      this.getlist()
    },
    // 分页
    handleSizeChange(size) {
      this.page_size = size
      this.tableData = []
      this.getlist()
    },
    //从DEAR加载未完成的销售订单
    loadOrders() {
      this.btn_loadinga = true
      this.$post({
        url: "/app/saleorder/loadOrders",
        data: {
          order_start_time: this.order_time && this.order_time.length ? this.order_time[0] : '',
          order_end_time: this.order_time && this.order_time.length ? this.order_time[1] : '',
          api_id: this.$store.state.user.api_id
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.show = false
            this.btn_loadinga = false
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadinga = false
          }, 1300);
        },
      })
    },
    // 当选择项发生变化时会触发该事件
    SelectionChange(data) {
      this.idsarr = data
    },
    //Generate Assembly Order生成装配订单
    generate() {
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.btn_loadingb = true
      this.$post({
        url: "/app/saleorder_goods/generateAssemblyOrderTip",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.generateAssemblyOrder(ids.join())
        },
        showtip: (res) => {
          this.$confirm(res.msg, {
            type: 'warning'
          }).then(() => {
            this.generateAssemblyOrder(ids.join())
          }).catch(() => {
            this.btn_loadingb = false
          });
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingb = false
          }, 1300);
        },
      })
    },
    generateAssemblyOrder(ids) {
      this.$post({
        url: "/app/saleorder_goods/generateAssemblyOrder",
        data: {
          api_id: this.$store.state.user.api_id,
          ids
        },
        success: (res) => {
          this.$notify({
            title: res.msg,
            type: 'success'
          });
          setTimeout(() => {
            this.btn_loadingb = false
          }, 1300);
          this.$nextTick(() => {
            this.pagechage(1)
          })
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingb = false
          }, 1300);
        },
      })
    },
    // deleteSaleOrder
    deleteorder() {
      if (!this.idsarr.length) {
        this.$message({
          message: 'Please Select Assembly Order',
          type: 'warning'
        });
        return
      }
      let ids = []
      this.idsarr.map((item) => {
        ids.push(item.id)
      })
      this.btn_loadingc = true
      this.$post({
        url: "/app/saleorder/deleteSaleOrder",
        data: {
          api_id: this.$store.state.user.api_id,
          ids: ids.join()
        },
        success: (res) => {
          this.$notify({
            title: 'success',
            type: 'success'
          });
          setTimeout(() => {
            this.btn_loadingc = false
            this.pagechage(1)
          }, 1300);
        },
        tip: () => {
          setTimeout(() => {
            this.btn_loadingc = false
          }, 1300);
        },
      })
    },
    // 合计方法
    // getSummaries(param) {
    //   const { columns, data } = param;
    //   const sums = [];
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = 'Sum';
    //       return;
    //     }
    //     switch (column.property) {  // column.property可以匹配它的每一列的命名， 然后赋值
    //       case "Quantity":
    //         sums[index] = this.Quantity //值取自后台
    //         break;
    //       default:
    //         break;
    //     }
    //   });
    //   return sums;
    // }
  },

}
</script>
<style lang='scss' scoped>
/deep/ .el-header[data-v-fae5bece] {
  padding: 0;
}

.indexbox {
  width: 100%;
  height: 100%;

  .searchbox {
    width: 100%;
    padding: 6px 15px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    /deep/ .left {
      // border: 1px solid red;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      // 输入框
      // .el-input__inner {
      //   font-size: 16px;
      //   font-weight: 400;
      // }

      // .el-input--small .el-input__inner {
      //   height: 40px;
      // }

      .inp {
        margin-right: 5px;
      }

      // 按钮
      .el-button {
        // padding: 9px 18px;
        // font-size: 20px;
        // font-weight: 500;
        color: #3b3b3b;
      }

      .indexbox .searchbox[data-v-007ed227] .el-button {
        margin: 0;
      }

      .name {
        // font-size: 16px;
        // font-weight: 400;
        color: #717988;
        padding: 0 20px 0 50px;
      }
    }

    .right {
      button {
        // font-size: 20px;
        // font-weight: 500;
        color: #3b3b3b;
        background-color: #d6f3e3;
        padding: 7px 15px;
        border-radius: 10px;
      }
    }

    /deep/ .el-button {
      background-color: #d6f3e3;
      border-radius: 10px;
      color: #3b3b3b;
      border-color: #d6f3e3;
      align-self: flex-end;
      margin: 5px;
    }
  }

  .tablebox {
    width: 100%;
    padding: 20px;
  }

  .formbox {
    /deep/ .el-form-item {
      margin-bottom: 30px !important;

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
  }

  .dataselect {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-dialog {
    min-width: 400px !important;
  }
}
</style>
